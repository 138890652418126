import { Link as GatsbyLink } from "gatsby";
import {
  Header as SdsHeader,
  HeaderCollapsibleMenu,
  HeaderNav,
} from "@sikt/sds-header";
import { Link } from "@sikt/sds-core";
import * as style from "./header.module.css";

const Header = ({ currentHref }: { currentHref: string }) => {
  return (
    <SdsHeader logoText="designsystem.sikt.no" className={style.header}>
      <HeaderCollapsibleMenu aria-label="Navigasjon">
        <HeaderNav>
          <GatsbyLink
            to="/grunnleggende/"
            aria-current={currentHref === "/grunnleggende/" && "page"}
            activeClassName="sds-header__nav-link--selected"
            partiallyActive
          >
            Grunnleggende
          </GatsbyLink>
          <GatsbyLink
            to="/komponenter/"
            aria-current={currentHref === "/komponenter/" && "page"}
            activeClassName="sds-header__nav-link--selected"
            partiallyActive
          >
            Komponenter
          </GatsbyLink>
          <Link href="/monstre/">Mønstre</Link>
        </HeaderNav>
      </HeaderCollapsibleMenu>
      <div />
    </SdsHeader>
  );
};

export default Header;
